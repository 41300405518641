import { useContext, useState } from 'react';
import { useRouter } from 'next/router';
import {
  setCodeInfo as setCodeInfoAction,
  setError,
  setFlowStage,
  setIsRegister,
  setFirstScreenVersion,
} from 'context/state/reducer';
import { AppContext } from 'context/GlobalContextProvider';
import { setAccountCookie, uuidv4 } from 'lib/utils/helper';
import { Events, handleTrackEvent } from 'lib/analytics/trackCalls';
import { useBoolean } from 'lib/hooks';
import { Profile } from 'lib/types/profileType';
import useFlowManager from 'lib/hooks/useFlowManager';

import {
  ACCOUNT_COOKIE_NAME_FOX,
  CODE_TYPE_API,
  COOKIE_CODE_INFO,
  FLOW_STAGE_MVPD_SELECT,
  FLOW_STAGE_PROFILE_AUTH,
  ROUTE_ACCOUNT,
  ROUTE_HOME,
  ROUTE_MVPD,
} from 'constants/index';

type Props = {
  profile: Profile;
  setRegCodeError: (b: boolean) => boolean | void;
};

const useRegCodeInput = ({
  profile,
  setRegCodeError,
}: Props): {
  handleSubmit: (regCode: string) => Promise<void>;
  setRegCode: (value: ((prevState: string) => string) | string) => void;
  regCode: string;
  error: boolean;
} => {
  const {
    dispatch,
    state: { secondScreenVersion },
  } = useContext(AppContext);
  const [regCode, setRegCode] = useState('');
  const { value: error, setTrue: setErrorTrue, setFalse: setErrorFalse } = useBoolean(false);
  const router = useRouter();
  const { cookies, setCookie, removeCookie } = useFlowManager();

  const handleSubmit = async (regCode: string) => {
    setErrorFalse();
    setRegCodeError(false);
    removeCookie(COOKIE_CODE_INFO);

    let response;

    try {
      response = await profile.validateRegCode({ regCode });
      const { firstScreenDeviceId, firstScreenVersion, isMvpd, isRegister } = response;

      const account = await profile.loginAnonymously({ deviceId: firstScreenDeviceId });

      if (firstScreenVersion) {
        await profile.setRegCodeStatus({
          regCode,
          firstScreenVersion,
          secondScreenVersion,
          additionalHeaders: {
            authorization: 'Bearer ' + account.accessToken,
          },
        });
        dispatch(setFirstScreenVersion(firstScreenVersion));
      }

      const info = {
        code: regCode,
        deviceId: uuidv4(),
        type: CODE_TYPE_API,
        ...response,
        secondScreenVersion,
      };

      setRegCode(regCode);

      handleTrackEvent({
        eventName: Events.PROFILE_INITIATED,
        props: {
          page_activation_code: regCode,
        },
      });

      setCookie(COOKIE_CODE_INFO, info, { path: ROUTE_HOME });
      dispatch(setCodeInfoAction(cookies[COOKIE_CODE_INFO]));

      // Now & Sports flow
      if (isRegister) dispatch(setIsRegister(isRegister));
      if (isMvpd) {
        // set Anonymous Account Cookie
        setAccountCookie(account);
        dispatch(setFlowStage(FLOW_STAGE_MVPD_SELECT));
        handleTrackEvent({
          eventName: Events.BUTTON_LINK_SELECTED,
          props: {
            page_item_title: Events.SUBMIT,
            page_destination_url: window.location.origin + ROUTE_MVPD,
          },
        });
        await router.push(ROUTE_MVPD);
      } else {
        dispatch(setFlowStage(FLOW_STAGE_PROFILE_AUTH));
        // remove Anonymous Account Cookie to go get a Profile Account cookie
        removeCookie(ACCOUNT_COOKIE_NAME_FOX);
        handleTrackEvent(
          {
            eventName: Events.BUTTON_LINK_SELECTED,
            props: {
              page_item_title: Events.SUBMIT,
              page_destination_url: window.location.origin,
            },
          },
          {
            eventName: Events.SUBMIT_CODE_COMPLETED,
            props: {
              page_active_code: regCode,
            },
          },
        );
        await router.push(ROUTE_ACCOUNT);
      }
    } catch (e) {
      dispatch(setError(e));
      dispatch(setIsRegister(false));
      const info = {
        code: regCode,
        ...response,
      };

      setCookie(COOKIE_CODE_INFO, info, { path: ROUTE_HOME });
      setRegCodeError(true);
      setErrorTrue();
      handleTrackEvent(
        {
          eventName: Events.BUTTON_LINK_SELECTED,
          props: {
            page_item_title: Events.SUBMIT,
            page_destination_url: window.location.origin,
          },
        },
        {
          eventName: Events.PROFILE_ACCESS_ACTIVATION_ERROR,
          props: {
            page_activation_code: regCode,
            page_error_description: 'no error description',
            page_error_type: 'invalid form input',
          },
        },
      );
    }
  };

  return {
    regCode,
    setRegCode,
    handleSubmit,
    error,
  };
};

export default useRegCodeInput;
