import React, { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { useGetProfileSdk } from 'lib/hooks';
import { profileConfig } from 'lib/utils/configs';
import {
  handleHomePageEvent,
  PageNames,
  PageTypes,
  setPageAnalyticsData,
  mountDataLayer,
} from 'lib/analytics/trackCalls';
import { setError, setFlowStage, setLoading } from 'context/state/reducer';
import { AppContext } from 'context/GlobalContextProvider';
import RegCodeInput from 'components/RegCodeInput';
import ErrorBanner from 'components/ErrorBanner';
import { Footer, Loader, SEO } from 'components/common';
import { FLOW_STAGE_ENTER_REG_CODE, ROUTE_ACCOUNT, ROUTE_MVPD } from '../constants';

const IndexPage = (): JSX.Element => {
  const [regCodeError, setRegCodeError] = useState(false);
  const { dispatch } = useContext(AppContext);
  const profile = useGetProfileSdk(profileConfig);
  const router = useRouter();

  useEffect(() => {
    dispatch(setLoading(false));
    dispatch(setError(''));
    dispatch(setFlowStage(FLOW_STAGE_ENTER_REG_CODE));
    router.prefetch(ROUTE_MVPD);
    router.prefetch(ROUTE_ACCOUNT);
  }, [dispatch, router]);

  useEffect(() => {
    mountDataLayer();
    setPageAnalyticsData({
      pageName: PageNames.SUBMIT_CODE_LANDING,
      pageType: PageTypes.ACTIVATION_CODE,
    });
    handleHomePageEvent();
    return () => setPageAnalyticsData({ pageName: '', pageType: '' });
  }, []);

  if (!profile) return <Loader abCenter />;

  return (
    <>
      <SEO title="Home" />
      <Home>
        <H2>
          Enter the code <br />
          displayed on your TV screen.
        </H2>
        <RegCodeInput profile={profile} setRegCodeError={setRegCodeError} />
      </Home>
      {regCodeError && <ErrorBanner />}
      <Footer />
    </>
  );
};

export default IndexPage;

const Home = styled.section`
  padding-top: 2rem;
`;

const H2 = styled.h2`
  max-width: 180px;
  padding: 0;
  margin: 0 auto 2rem;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 1.3em;
  text-align: center;
`;
