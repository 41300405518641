// eslint-disable-next-line @typescript-eslint/no-var-requires
const { yupResolver } = require('@hookform/resolvers/yup');
import { FieldValues, ResolverOptions, ResolverResult, UnpackNestedValue } from 'react-hook-form';
import * as yup from 'yup';

const RegCodeSchema = (): (<TFieldValues extends FieldValues, TContext>(
  values: UnpackNestedValue<TFieldValues>,
  context: TContext | undefined,
  options: ResolverOptions<TFieldValues>,
) => Promise<ResolverResult<TFieldValues>>) =>
  yupResolver(
    yup
      .object()
      .shape({
        regCode: yup
          .string()
          .nullable()
          .required('Reg code is required')
          .min(4, 'Min length is 4 characters'),
      })
      .required(),
  );

export default RegCodeSchema;
