import React from 'react';
import styled from 'styled-components';
import color from 'styles/colors';
import alertIcon from 'images/global/alert_white.svg';

const ErrorBanner = (): JSX.Element => {
  return (
    <StyledErrorBanner>
      <div className="exclamationPoint" />
      <div className="messageText">Invalid activation code. Please try again.</div>
    </StyledErrorBanner>
  );
};

export default ErrorBanner;

const StyledErrorBanner = styled.section`
  position: fixed;
  bottom: 0;
  display: flex;
  overflow: hidden;
  width: 100%;
  max-width: 350px;
  height: 30px;
  align-items: center;
  justify-content: center;
  padding: 0 0 0 0.8rem;
  background: ${color.Monza};
  .exclamationPoint {
    position: absolute;
    left: 10px;
    width: 100%;
    max-width: 19px;
    height: 18px;
    padding: 0;
    margin: 0;
    background: url(${alertIcon}) no-repeat;
  }
  .messageText {
    color: ${color.white};
    font-size: 11px;
  }
`;
